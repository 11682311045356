<template>
  <div class="box m_tb_20">
    <!--    标题-->
    <div
      class="flex_row title-box p_tb_10"
      style="position: relative; height: 51px">
      <div
        style="
          position: absolute;
          width: 5px;
          height: 20px;
          background-color: #3b8989;
          border-radius: 2px;
          top: 50%;
          margin-top: -10px;
        "></div>
      <span
        class="fs_20 fw_bold m_lr_10 flex_row flex_align_center"
        style="color: #0f4444">
        <div>{{ title }}</div>
        <div style="margin-left: 20px"><slot name="sort"></slot></div>
      </span>
      <div @click="getMore">
        <span
          v-show="showMore"
          class="m_t_15 pointer"
          style="color: #0f4444; opacity: 0.6">
          更多
          <i class="icon-font el-icon-caret-right"></i>
        </span>
      </div>
    </div>
    <!--    内容-->
    <slot>
      <el-row class="flex_row"></el-row>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'CourseList',
  props: {
    showMore: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data: () => {
    return {};
  },
  methods: {
    getMore() {
      this.$emit('getMore');
    },
  },
};
</script>
<style scoped lang="scss">
.box {
  width: 100%;
}
.title-box {
  justify-content: space-between;

  display: flex;
  align-items: center;
}

.course-box {
  width: 285px;
  margin-right: 20px;
  position: relative;
  transition: all 0.5s;
}

.course-box:last-child {
  margin: 0;
}

.course-box:hover {
  box-shadow: 0 0 10px 2px #e0e0e0;
  transform: translateY(-8px);
  transition: all 0.5s;
}

.tag {
  position: absolute;
  top: 0;
  left: 0;
}

.live_tag {
  position: absolute;
  right: 10px;
}
</style>
