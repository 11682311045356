<template>
  <div
    class="content"
    @click="toDetail"
    :style="cssVars">
    <div class="pic">
      <img :src="courseInfo.coverUrl" />
      <div class="type">{{ courseInfo.classify }}</div>
    </div>
    <div class="info">
      <div class="title" :title="courseInfo.name">{{ courseInfo.name }}</div>
      <div class="list">
        <i class="el-icon-user"></i>
        主讲人:{{ courseInfo.author }}
      </div>
      <div class="list">
        <i class="el-icon-office-building"></i>
        单位:{{ courseInfo.school }}
      </div>
      <div class="list">
        <i class="el-icon-view"></i>
        浏览量:{{ courseInfo.browseNumber }}人
      </div>
      <div class="list">
        <i class="el-icon-thumb"></i>
        点赞量:{{ courseInfo.linkNum }}人
      </div>
      <div class="list">
        <i class="el-icon-video-play"></i>
        实时观看人数:{{ courseInfo.onlineCount }}人
      </div>
    </div>
  </div>
</template>

<script>
import { sessionGet, sessionSet } from '@/utils/local.js';
export default {
  data() {
    return {
      color: '',
      courseInfo: '',
    };
  },
  props: {
    info:'',
  },
  created() {
    const value = sessionGet('classify');
    this.courseInfo = JSON.parse(JSON.stringify(this.info));
    value.map((item) => {
      if (item.id == this.info.contentsId) {
        this.courseInfo.classify = item.name;
      }
    });
    switch (this.courseInfo.classify) {
      case '教师风采大观':
        this.color = '#3B8989';
        break;
      case '教育创新研究室':
        this.color = '#A04139';
        break;
      case '教育技术实验室':
        this.color = '#D67E49';
        break;
      case '国家平台报告厅':
        this.color = '#88AF63';
        break;
      default:
        this.color = '#3B8989';
    }
  },
  methods: {
    toDetail() {
      this.$router.push({
        path: '/Detail',
        query: { courseId: this.info.id },
      });
    },
  },
  computed: {
    cssVars() {
      return {
        '--color': this.color,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  cursor: pointer;
  width: 290px;
  height: 330px;
  background-color: #fff;
  border-radius: 10px;
  .pic {
    position: relative;
    height: 140px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover
    }
    .type {
      position: absolute;
      right: 0px;
      top: 0;
      color: #fff;
      font-size: 14px;
      // width: 98px;
      padding: 8px;
      height: 26px;
      background: var(--color);
      border-radius: 0px 5px 0px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .info {
    padding: 0 10px;
    .title {
      font-size: 16px;
      font-weight: 500;
      margin-top: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .list {
      font-size: 14px;
      opacity: 0.6;
      margin-top: 5px;
    }
  }
  img {
    height: 160px;
    width: 100%;
    object-fit: contain;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}
</style>
