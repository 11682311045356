<template>
  <div
    class="home"
    v-loading="loading">
    <!--    课程菜单-->
    <el-row class="course-menu m_tb_20">
      <el-col
        :span="24"
        style="
          border-radius: 0.5rem;
          overflow: hidden;
          margin-left: 20px;
          position: absolute;
          right: 0;
        ">
        <el-carousel
          trigger="hover"
          height="270px">
          <el-carousel-item
            v-for="(item, index) in bannerList"
            :key="index"
            style="background: lightgray">
            <router-link :to="{ path: item.hrefUrl }">
              <img
                width="100%"
                height="100%"
                :src="item.bannerUrl"
                style="object-fit: cover"
                alt="banner" />
            </router-link>
          </el-carousel-item>
        </el-carousel>
      </el-col>
    </el-row>
    <!--内容-->
    <el-row class="flex_row flex_justify_between row">
      <Title
        title="本期亮点"
        style="padding-right: 6px">
        <el-carousel
          class="lightspot"
          height="380px">
          <el-carousel-item
            v-for="item in currentHotIssue"
            :key="item.id"
            @click.native="
              $router.push({ path: '/Detail', query: { courseId: item.id } })
            ">
            <div
              :style="
                `position: absolute;
                right: 0;
                padding: 8px;
                height: 26px;
                border-radius: 0px 5px 0px 10px;
                display: flex;
                align-items: center;
                color:#fff;
                font-size: 14px;
                justify-content: center;
                background-color: ` +
                item.color +
                `;`
              ">
              {{ item.classify }}
            </div>
            <img :src="item.coverCfxUrl ? item.coverCfxUrl : item.coverUrl" />
            <div class="info">
              <div class="title">{{ item.name }}</div>
              <div class="list">
                <span>
                  <i class="el-icon-user"></i>
                  主讲人:{{ item.author }}
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span>
                  <i class="el-icon-office-building"></i>
                  单位:{{ item.school }}
                </span>
              </div>
              <div
                class="list"
                v-html="item.introduce"></div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </Title>
      <Title
        title="热门推荐"
        :showMore="true"
        @getMore="getMore"
        style="padding-left: 6px">
        <template #sort>
          <SearchBox
            :options="['浏览量排序', '点赞量排序']"
            @change="sort"></SearchBox>
        </template>
        <!-- <vue-seamless-scroll
          :data="hotResources"
          class="roll-box"
          :class-option="classOption"> -->
        <div class="roll-box">
          <CourseList
            v-for="item in hotResources"
            :key="item.id"
            class="list"
            :info="item"></CourseList>
          <div style="text-align: center">
            <el-pagination
              @current-change="current_change"
              :page-size="parmas.size"
              layout=" prev, pager, next"
              :total="parmas.total"></el-pagination>
          </div>
        </div>
        <!-- </vue-seamless-scroll> -->
      </Title>
    </el-row>
    <el-row>
      <Title
        title="精彩往期"
        :showMore="true"
        @getMore="getMore">
        <div class="course-list">
          <Course
            v-for="item in pastWonderfulData"
            :key="item.id"
            :info="item"></Course>
        </div>
        <el-empty v-show="pastWonderfulData.length == 0"></el-empty>
      </Title>
    </el-row>

    <div style="position: fixed;
    left: 10px;
    top: 48%;
    z-index: 100;width: 50px;">
        <div class="butColor" src="" @click="toZB"><i class="el-icon-video-camera"></i>进入直播</div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/8.30/Title';
import { sessionGet } from '../../utils/local';
import SearchBox from '@/components/8.30/SearchBox';
import Course from '@/components/8.30/Course';
import CourseList from '@/components/8.30/CourseList';
import {
  getCurrentHotIssue,
  getHotResources,
  getPastWonderfulData,
  getResourcesClassifyPage,
  getBanner,
} from '@/api/8.30';
export default {
  name: 'index',
  components: {
    Title,
    Course,
    CourseList,
    SearchBox,
  },
  data: () => {
    return {
      color: '',
      loading: false,
      userInfo: sessionGet('userInfo'),
      classOption: {
        step: 0.5,
        limitMoveNum: 4,
      },
      parmas: {
        order: 'desc',
        orderColumn: 'browseNumber',
        total: 0,
        current: 1,
        size: 3,
      },
      currentHotIssue: '',
      hotResources: [],
      pastWonderfulData: [],
      classify: [], //分类
      bannerList: [],
    };
  },
  async created() {
    this.loading = true;
    //获取本期亮点
    const { data: currentHotIssue } = await getCurrentHotIssue();
    //重组数据
    this.currentHotIssue = currentHotIssue.records;
    const value = sessionGet('classify');
    this.currentHotIssue.map((item1) => {
      value.map((item) => {
        if (item.id == item1.contentsId) {
          item1.classify = item.name;
          switch (item.name) {
            case '教师风采大观':
              item1.color = '#3B8989';
              break;
            case '教育创新研究室':
              item1.color = '#A04139';
              break;
            case '教育技术实验室':
              item1.color = '#D67E49';
              break;
            case '国家平台报告厅':
              item1.color = '#88AF63';
              break;
            default:
              item1.color = '#3B8989';
          }
        }
      });
    });
    console.log(this.currentHotIssue);
    //获取热门推荐
    this.gethotResources();
    //往期精彩数据
    const { data: pastWonderfulData } = await getPastWonderfulData();
    this.pastWonderfulData = pastWonderfulData.records;
    // //查询首页分类
    // const { data: classifyPage } = await getResourcesClassifyPage({
    //   name: '八点半学院',
    // });
    // this.classify = classifyPage.records[0].contentsList;
    //组合banner数据（本期亮点、下期预告、征集要求
    //获取banner数据
    const { data: banner } = await getBanner({ type: 3 });
    this.bannerList = banner;
    // this.bannerList = [
    //   {
    //     coverUrl:
    //       this.currentHotIssue.length > 0
    //         ? this.currentHotIssue[0].coverUrl
    //         : require('@/assets/image/bqld.png'),
    //     url: '/More',
    //     query: '',
    //   },
    //   {
    //     coverUrl: require('@/assets/image/xqyg.png'),
    //     url: '/Content',
    //     query: '1702251616474083330',
    //   },
    //   {
    //     coverUrl: require('@/assets/image/zjyq.png'),
    //     url: '/Content',
    //     query: '1702250469562626050',
    //   },
    // ];

    this.loading = false;
  },
  methods: {
    sort(e) {
      this.parmas.orderColumn = e == '浏览量排序' ? 'browseNumber' : 'linkNum';
      this.gethotResources();
    },
    //获取热门推荐
    async gethotResources() {
      const { data: hotResources } = await getHotResources(this.parmas);

      if (hotResources.total > 6) {
        this.parmas.total = 6;
      } else {
        this.parmas.total = hotResources.total;
      }

      this.hotResources = hotResources.records;
    },
    current_change(page) {
      this.parmas.current = page;
      this.gethotResources();
    },
    getMore() {
      this.$router.push({ path: '/More' });
    },
    // 进入直播
    toZB() {
      window.open("https://www.wuhousmartedu.com/#/liveStu/player");
    },
  },
};
</script>
<style scoped lang="scss">
.home {
  width: 120rem;
  margin: 1rem auto;
  position: relative;
}
.row {
}
.roll-box {
  height: 380px;
  background-color: #fff;
  border-radius: 10px;
  .list {
    margin-bottom: 20px;
  }
}

.lightspot {
  height: 380px;
  cursor: pointer;
  border-radius: 10px;
  background-color: #fff;
  img {
    width: 100%;
    height: 266px;
    object-fit: cover;
    border-radius: 10px;
  }
  .info {
    padding: 0 10px;
    .title {
      font-size: 16px;
      margin-top: 5px;
    }
    .list {
      margin-top: 6px;
      font-size: 14px;
      opacity: 0.6;
    }
  }
}
.course-list {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-auto-rows: 350px;
}
.course-menu {
  height: 270px;
  position: relative;
}
</style>
<style>

  .butColor{
    background-color: #a2c8ca;
    cursor: pointer;
    width: 25px;
    height: 100px;
    color: #ffffff;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .butColor:hover{
    background-color: #3b8989;
  }
</style>
