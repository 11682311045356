<template>
  <div>
    <el-select
      v-model="selected"
      @change="$emit('change', $event)">
      <el-option
        :label="item"
        :value="item"
        v-for="(item, index) in options"
        :key="index"></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: '',
    };
  },
  props: {
    options: {
      type: Array,
      default: [],
    },
  },
  created() {
    this.selected = this.options[0];
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-input {
  border-radius: 17px;
  width: 120px;
  height: 28px;
}
::v-deep .el-input__inner {
  height: 28px;
  line-height: 28px;
  border: 1px solid #a2c8ca;
  border-radius: 17px;
  color: #0f4444;
}
::v-deep .el-input__icon {
  line-height: 28px;
}
::v-deep .el-select .el-input.is-focus .el-input__inner {
  border-color: #a2c8ca !important;
}
::v-deep .el-select .el-input__inner:focus {
  border-color: #a2c8ca !important;
}
.el-select-dropdown__item.selected {
  color: #0f4444 !important;
  font-size: 14px;
  font-weight: 500;
}
</style>
