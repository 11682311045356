<template>
  <div
    class="content"
    @click="toDetail">
    <div class="pic">
      <img :src="info.coverUrl" />
    </div>
    <div class="info">
      <div class="title">{{ info.name }}</div>
      <div class="box">
        <div class="list">
          <i class="el-icon-user"></i>
          主讲人:{{ info.author }}
        </div>
        <div class="list">
          <i class="el-icon-office-building"></i>
          单位:{{ info.school }}
        </div>
        <div class="list">
          <i class="el-icon-view"></i>
          浏览量:{{ info.browseNumber }}人
        </div>
        <div class="list">
          <i class="el-icon-thumb"></i>
          点赞量:{{ info.linkNum }}人
        </div>
        <div class="list">
          <i class="el-icon-video-play"></i>
          实时观看人数:{{ info.onlineCount }}人
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    info: '',
  },
  methods: {
    toDetail() {
      this.$router.push({
        path: '/Detail',
        query: { courseId: this.info.id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  cursor: pointer;

  height: 92px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;

  .info {
    padding: 0 10px;
    width: 100%;
    .title {
      font-size: 16px;
      font-weight: 500;
      margin-top: 5px;
    }
    .box {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 34%);
      .list {
        font-size: 14px;
        opacity: 0.6;
        margin-top: 4px;
      }
    }
  }
  img {
    height: 92px;
    width: 92px;
    object-fit: cover;
    border-radius: 10px;
  }
}
</style>
