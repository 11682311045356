var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"home"},[_c('el-row',{staticClass:"course-menu m_tb_20"},[_c('el-col',{staticStyle:{"border-radius":"0.5rem","overflow":"hidden","margin-left":"20px","position":"absolute","right":"0"},attrs:{"span":24}},[_c('el-carousel',{attrs:{"trigger":"hover","height":"270px"}},_vm._l((_vm.bannerList),function(item,index){return _c('el-carousel-item',{key:index,staticStyle:{"background":"lightgray"}},[_c('router-link',{attrs:{"to":{ path: item.hrefUrl }}},[_c('img',{staticStyle:{"object-fit":"cover"},attrs:{"width":"100%","height":"100%","src":item.bannerUrl,"alt":"banner"}})])],1)}),1)],1)],1),_c('el-row',{staticClass:"flex_row flex_justify_between row"},[_c('Title',{staticStyle:{"padding-right":"6px"},attrs:{"title":"本期亮点"}},[_c('el-carousel',{staticClass:"lightspot",attrs:{"height":"380px"}},_vm._l((_vm.currentHotIssue),function(item){return _c('el-carousel-item',{key:item.id,nativeOn:{"click":function($event){return _vm.$router.push({ path: '/Detail', query: { courseId: item.id } })}}},[_c('div',{style:(`position: absolute;
              right: 0;
              padding: 8px;
              height: 26px;
              border-radius: 0px 5px 0px 10px;
              display: flex;
              align-items: center;
              color:#fff;
              font-size: 14px;
              justify-content: center;
              background-color: ` +
              item.color +
              `;`)},[_vm._v(" "+_vm._s(item.classify)+" ")]),_c('img',{attrs:{"src":item.coverCfxUrl ? item.coverCfxUrl : item.coverUrl}}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"list"},[_c('span',[_c('i',{staticClass:"el-icon-user"}),_vm._v(" 主讲人:"+_vm._s(item.author)+" ")]),_vm._v("       "),_c('span',[_c('i',{staticClass:"el-icon-office-building"}),_vm._v(" 单位:"+_vm._s(item.school)+" ")])]),_c('div',{staticClass:"list",domProps:{"innerHTML":_vm._s(item.introduce)}})])])}),1)],1),_c('Title',{staticStyle:{"padding-left":"6px"},attrs:{"title":"热门推荐","showMore":true},on:{"getMore":_vm.getMore},scopedSlots:_vm._u([{key:"sort",fn:function(){return [_c('SearchBox',{attrs:{"options":['浏览量排序', '点赞量排序']},on:{"change":_vm.sort}})]},proxy:true}])},[_c('div',{staticClass:"roll-box"},[_vm._l((_vm.hotResources),function(item){return _c('CourseList',{key:item.id,staticClass:"list",attrs:{"info":item}})}),_c('div',{staticStyle:{"text-align":"center"}},[_c('el-pagination',{attrs:{"page-size":_vm.parmas.size,"layout":" prev, pager, next","total":_vm.parmas.total},on:{"current-change":_vm.current_change}})],1)],2)])],1),_c('el-row',[_c('Title',{attrs:{"title":"精彩往期","showMore":true},on:{"getMore":_vm.getMore}},[_c('div',{staticClass:"course-list"},_vm._l((_vm.pastWonderfulData),function(item){return _c('Course',{key:item.id,attrs:{"info":item}})}),1),_c('el-empty',{directives:[{name:"show",rawName:"v-show",value:(_vm.pastWonderfulData.length == 0),expression:"pastWonderfulData.length == 0"}]})],1)],1),_c('div',{staticStyle:{"position":"fixed","left":"10px","top":"48%","z-index":"100","width":"50px"}},[_c('div',{staticClass:"butColor",attrs:{"src":""},on:{"click":_vm.toZB}},[_c('i',{staticClass:"el-icon-video-camera"}),_vm._v("进入直播")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }